import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  const { site, file } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          keywords
          image
          social {
            linkedin
            github
          }
          locale
          type
        }
      }
      file(relativePath: { eq: "share.png" }) {
        publicURL
      }
    }
  `);

  const {
    title,
    description,
    author,
    siteUrl,
    keywords,
    image,
    social,
    locale,
    type,
  } = site.siteMetadata;

  const metaImage = file ? file.publicURL : image;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      defer={false}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:locale`,
          content: locale,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ]}
      link={[
        {
          rel: "canonical",
          href: siteUrl,
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Person",
          "name": author,
          "url": siteUrl,
          "sameAs": [
            `https://www.linkedin.com/in/${social.linkedin}`,
            `https://github.com/${social.github}`,
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
