import React from "react";
import Heading from "../../components/Heading";
import { FaInfoCircle } from "../../components/Icons";

function Contact() {
  const blockStyle = "mb-6 md:mb-10";
  const labelStyle = "uppercase  font-bold text-xl font-header ";
  const inputStyle =
    "w-full px-3 py-3 mt-2 text-lg border-2 rounded border-gray text-black focus:border-primary-500 focus:outline-none font-body bg-gray-100";
  return (
    <section id='contact' className='py-5'>
      <Heading icon={FaInfoCircle} title='Contact Me' />

      <form
        action='https://formspree.io/f/mbjwdodp'
        method='POST'
        className='md:w-2/3 m-auto'
      >
        {/* name */}
        {/* <input type='hidden' name='contact' value='contact' /> */}
        <div className='flex flex-col md:flex-row'>
          <div className={blockStyle + "w-full md:w-1/2 md:mr-6"}>
            <label className={labelStyle} htmlFor='firstname'>
              <h5>First name</h5>
              <input
                type='text'
                id='firstname'
                name='First Name'
                className={inputStyle}
                placeholder='Jim'
                required
              />
            </label>
          </div>

          <div className={blockStyle + "w-full md:w-1/2"}>
            <label className={labelStyle} htmlFor='lastname'>
              <h5>Last name</h5>
              <input
                type='text'
                id='lastname'
                name='Last Name'
                className={inputStyle}
                placeholder='Halpert'
                required
              />
            </label>
          </div>
        </div>

        <div className={blockStyle}>
          <label className={labelStyle} htmlFor='email'>
            <h5>Email</h5>
            <input
              type='email'
              id='email'
              name='email'
              className={inputStyle}
              placeholder='jimhalpert@yahoo.com'
              required
            />
          </label>
        </div>

        <div className={blockStyle}>
          <label className={labelStyle}>
            <h5>Message</h5>
            <textarea
              name='Message'
              className={inputStyle}
              rows='8'
              required
            ></textarea>
          </label>
        </div>

        <div className='flex justify-center'>
          <button
            type='submit'
            className='px-12 py-2 mt-6 text-xl font-bold text-center text-white uppercase rounded-full cursor-pointer font-header bg-primary-500 hover:bg-primary-600 md:text-xl focus:outline-none'
            aria-label='Send an email'
          >
            Send
          </button>
        </div>
      </form>
    </section>
  );
}

export default Contact;
