import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Heading from "../../components/Heading";
import { FaTrophy, FaAngleRight } from "../../components/Icons";
import styles from "./Achievements.module.css";

export default function Achievements() {
  const data = useStaticQuery(graphql`
    {
      allAchievementsJson {
        edges {
          node {
            id
            title
            position
            date
          }
        }
      }
    }
  `);
  return (
    <section id='achievements'>
      <Heading icon={FaTrophy} title='Achievements' />

      {data.allAchievementsJson.edges.map(({ node }, index) => (
        <div
          key={node.id}
          className={`${styles.container} wow fadeInDown`}
          style={{
            animationDuration: `${index * 200 + 500}ms`,
          }}
        >
          <div className='mt-1 pr-6'>
            <FaAngleRight />
          </div>
          <div>
            <h6 className='font-semibold'>
              {node.title} [{node.date}]
            </h6>
            <p className='font-thin text-sm'>{node.position}</p>
          </div>
        </div>
      ))}
    </section>
  );
}
