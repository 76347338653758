import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Heading from "../../components/Heading";
import { FaUser, FaAngleRight } from "../../components/Icons";
import styles from "./Responsibilities.module.css";

export default function Responsibilities() {
  const data = useStaticQuery(graphql`
    {
      allResponsibilitiesJson {
        edges {
          node {
            id
            title
            date
          }
        }
      }
    }
  `);

  return (
    <section id='responsibilities'>
      <Heading icon={FaUser} title='Responsibilities' />

      {data.allResponsibilitiesJson.edges.map(({ node }, index) => (
        <div
          key={node.id}
          className={`${styles.container} wow fadeInDown`}
          style={{
            animationDuration: `${index * 200 + 500}ms`,
          }}
        >
          <div className='mt-1 pr-6'>
            <FaAngleRight />
          </div>
          <div>
            <h6 className='font-semibold'>{node.title}</h6>
            <p className='font-thin text-sm'>{node.date}</p>
          </div>
        </div>
      ))}
    </section>
  );
}
