import { graphql, useStaticQuery } from "gatsby";

import Parallax from "parallax-js";
import React, { useRef, useState, useEffect } from "react";
import Subtitle from "../../components/Subtitle";

import me from "../../images/me.jpg";

const Hero = () => {
  const parallaxRef = useRef(null);
  const [parallax, setParallax] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const data = useStaticQuery(graphql`
    {
      photo: file(relativePath: { eq: "me.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 256, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { isMobile } = require("../../utils");
      setIsMobile(isMobile);
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setParallax(
        new Parallax(parallaxRef.current, {
          invertX: false,
          invertY: false,
        })
      );
    }

    return () => {
      parallax && parallax.destroy();
    };
  }, [parallaxRef]);

  return (
    <section id='hero' className='min-h-screen flex items-center container'>
      <div className='w-full grid grid-cols-1 md:grid-cols-2 row-gap-8 lg:gap-16 justify-center lg:justify-start mt-8 md:mt-12 lg:mt-0'>
        <div ref={parallaxRef} className='col-span-1 mx-auto'>
          <div>
            <img
              src={me}
              alt=''
              className='w-58 h-58 rounded-full mx-auto border-4 border-primary-300 wow fadeInLeft'
            />
          </div>
        </div>
        <div className='col-span-1 mx-auto'>
          <div>
            <span className='bg-clip-text text-transparent bg-gradient-to-br from-blue-700 via-blue-500 to-teal-400'></span>{" "}
            <Subtitle onDone={() => setShowSocial(true)} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
