import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { graphql, useStaticQuery, Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import React, { useContext, useEffect, useState } from "react";
import { animateScroll as scroll, scroller } from "react-scroll";
import ThemeContext from "../../context/ThemeContext";

import { IoIosMoon, IoIosSunny, MdMenu } from "../Icons";

const Navigation = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { dark, toggleDark } = useContext(ThemeContext);
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { isMobile } = require("../../utils");
      setIsMobile(isMobile);
    }
  }, []);

  const scrollToTop = () =>
    scroll.scrollToTop({
      delay: 50,
      duration: 600,
      smooth: "easeInOutCubic",
    });

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      delay: 50,
      offset: -50,
      duration: 600,
      smooth: "easeInOutCubic",
    });
  };

  const SectionLink = (x) => {
    const Icon = x.icon;

    return (
      <Tooltip title={x.title} placement='right' arrow>
        <div key={x.id} onClick={() => scrollTo(x.id)}>
          <Icon />
        </div>
      </Tooltip>
    );
  };

  return (
    // <div
    //   className={` animated ${
    //     isMobile ? "fadeInDown" : "fadeInLeft"
    //   }`}
    // >
    //   <div className=' cursor-pointer' onClick={scrollToTop}>
    //     <GatsbyImage className='' {...data.icon.childImageSharp} />
    //   </div>
    //   <div className=' cursor-pointer'>
    //     <Link to='/'>Blog</Link>
    //   </div>
    //   <Tooltip
    //     TransitionComponent={Fade}
    //     TransitionProps={{ timeout: 600 }}
    //     title={dark ? "Turn on the lights" : "Turn off the lights"}
    //   >
    //     <div
    //       className='cursor-pointer hover:text-primary-500'
    //       onClick={toggleDark}
    //     >
    //       {dark ? <IoIosSunny /> : <IoIosMoon />}
    //     </div>
    //   </Tooltip>
    // </div>
    <div>
      <nav className='container flex items-center justify-between mx-auto pt-8 px-4'>
        <div
          className='transform cursor-pointer transition ease-in-out delay-150 hover:translate-y-1 hover:scale-110 duration-300'
          onClick={scrollToTop}
        >
          <GatsbyImage className='w-32' {...data.icon.childImageSharp} />
        </div>
        <div className='block md:hidden'>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className=' focus:outline-none px-3 py-2 text-teal-200 focus:text-teal-400   hover:text-teal-300'
          >
            <svg fill='currentColor' viewBox='0 0 20 20' className='w-6 h-6'>
              <path
                className={isOpen ? "hidden" : "block"}
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
                clipRule='evenodd'
              ></path>
              <path
                className={isOpen ? "block" : "hidden"}
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>
        </div>
        <ul className='hidden md:flex items-center uppercase font-bold tracking-wide'>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("skills")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500'
            >
              Skills
            </div>
          </li>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("projects")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500'
            >
              Projects
            </div>
          </li>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("work")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500'
            >
              Work
            </div>
          </li>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("contact")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500'
            >
              Contact
            </div>
          </li>
          <li className='mr-8'>
            <Link to='/more' className='hover:no-underline'>
              <div className='cursor-pointer   hover:text-primary-500'>
                More
              </div>
            </Link>
          </li>
          {/* <li className='mr-8'>
            <Link to='/' className='hover:no-underline  hover:text-primary-500'>
              Blog
            </Link>
          </li> */}
          <li>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={dark ? "Turn on the lights" : "Turn off the lights"}
            >
              <div
                className='cursor-pointer hover:text-primary-500'
                onClick={toggleDark}
              >
                {dark ? <IoIosSunny /> : <IoIosMoon />}
              </div>
            </Tooltip>
          </li>
        </ul>
      </nav>
      <div className={isOpen ? "block px-4 mt-2 md:hidden" : "hidden"}>
        {/* px-4 mt-2 */}
        <ul className='uppercase inline-block font-bold tracking-wide'>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("skills")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500 py-1 rounded hover:bg-gray-100'
            >
              Skills
            </div>
          </li>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("projects")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500 py-1 mt-1 rounded hover:bg-gray-100'
            >
              Projects
            </div>
          </li>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("work")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500 py-1 mt-1 rounded hover:bg-gray-100'
            >
              Work
            </div>
          </li>
          <li className='mr-8'>
            <div
              onClick={() => scrollTo("contact")}
              className='cursor-pointer hover:no-underline  hover:text-primary-500 py-1 mt-1 rounded hover:bg-gray-100'
            >
              Contact
            </div>
          </li>
          <li className='mr-8'>
            <Link to='/more' className='hover:no-underline'>
              <div className='cursor-pointer hover:no-underline  hover:text-primary-500 py-1 mt-1 rounded hover:bg-gray-100'>
                More
              </div>
            </Link>
          </li>

          {/* <li className='mr-8'>
            <Link
              to='/'
              className='hover:no-underline  hover:text-primary-500 py-1 mt-1 rounded hover:bg-gray-100'
            >
              Blog
            </Link>
          </li> */}
          <li>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={dark ? "Turn on the lights" : "Turn off the lights"}
              className='py-1 mt-1 rounded'
            >
              <div
                className='cursor-pointer hover:text-primary-500'
                onClick={toggleDark}
              >
                {dark ? <IoIosSunny /> : <IoIosMoon />}
              </div>
            </Tooltip>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
