import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";

import React from "react";
import Button from "../../components/Button";
import Heading from "../../components/Heading";
import { FaDev, FaGithub, FaLink } from "../../components/Icons";

const Projects = () => {
  const data = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            id
            title
            description
            tags
            date
            github
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section id='projects'>
      <Heading icon={FaDev} title='Projects' />

      <div className='pb-8 grid grid-cols-1  row-gap-8 gap-2 md:grid-cols-2 lg:grid-cols-3'>
        {data.allProjectsJson.edges.map(({ node }, index) => (
          <div
            key={node.id}
            className=' flex flex-col max-w-xs mx-auto rounded-lg wow fadeIn'
            style={{
              animationDelay: `${index * 300 + 300}ms`,
            }}
          >
            <a
              href={node.website || node.github}
              target='_blank'
              className='w-full h-48 bg-black relative flex-center cursor-pointer rounded-lg shadow-lg'
            >
              <FaLink className='absolute' color='#FFF' size='5rem' />
              <GatsbyImage
                className='absolute w-full h-full object-cover rounded-lg hover:opacity-50 duration-200'
                {...node.image.childImageSharp}
              />
              <span className='sr-only'>{node.title}</span>
            </a>

            <h5 className='mt-4 font-semibold text-center'>{node.title}</h5>
            <p className='mt-2 pb-5 text-sm text-justify'>{node.description}</p>

            <p className='pb-5 flex flex-wrap text-xs font-semibold'>
              {node.tags.map((x) => (
                <span key={x} className='mr-2'>
                  #{x}
                </span>
              ))}
            </p>
          </div>
        ))}
      </div>

      <Button
        className='m-0'
        icon={FaGithub}
        title='More on GitHub'
        onClick={() =>
          window.open("https://github.com/sanfernoronha", "_blank")
        }
      />
    </section>
  );
};

export default Projects;
