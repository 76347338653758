import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Heading from "../../components/Heading";
import GIF from "../../images/about-me/me.gif";
import { MdPerson } from "../../components/Icons";

const AboutMe = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { id: { eq: "about-me" } }) {
        html
      }
    }
  `);

  return (
    <section id='about-me'>
      <Heading icon={MdPerson} title='About Me' />
      <div className='grid lg:grid-cols-6 gap-12 items-center'>
        <div className='hidden md:block lg:col-span-2 w-1/3 lg:w-3/4 mx-auto wow fadeInLeft'>
          <img
            src={GIF}
            alt='programmer'
            width='600px'
            style={{ borderRadius: 15 }}
          />
        </div>
        <div
          className='text-justify lg:col-span-4 wow fadeIn'
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </div>
    </section>
  );
};

export default AboutMe;
