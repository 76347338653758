import React from "react";
import PropTypes from "prop-types";
import Typist from "react-typist";

const Subtitle = ({ onDone }) => (
  <Typist
    startDelay={500}
    avgTypingDelay={30}
    cursor={{ show: false }}
    className='flex lg:h-32'
    onTypingDone={onDone}
  >
    <code className='w-full text-sm  leading-loose'>
      <div>
        <span className='text-blue-600'>var</span>{" "}
        <span className='text-orange-400'>sanfer</span>{" "}
        <span className='text-blue-600'>=</span> {"{"}
      </div>
      <div className='ml-8 pl-8 lg:ml-0  text-left'>
        <span className='text-red-500'>Software Developer</span>, <br />
        <span className='text-red-500'>Cloud Engineer</span>, <br />
        <span className='text-red-500'>Business Analyst</span>, <br />
        <span className='text-red-500'>ML Enthusiast</span>, <br />
        <span className='text-red-500'>Musician</span>, <br />
        <span className='text-red-500'>Reader</span>,{" "}
      </div>
      <div className='ml-8 pl-8 lg:ml-0 text-left'>
        <span className='text-red-500'>...</span>{" "}
      </div>
      <div>{"}"};</div>
    </code>
  </Typist>
);

Subtitle.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default Subtitle;
