import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Heading from "../../components/Heading";
import { AiFillSafetyCertificate, FaAngleRight } from "../../components/Icons";
import styles from "./Qualifications.module.css";

const Qualifications = () => {
  const data = useStaticQuery(graphql`
    {
      allQualificationsJson {
        edges {
          node {
            id
            title
            link
          }
        }
      }
    }
  `);

  return (
    <section id='qualifications'>
      <Heading icon={AiFillSafetyCertificate} title='Certifications' />

      {data.allQualificationsJson.edges.map(({ node }, index) => (
        <div
          key={node.id}
          className={`${styles.container} wow fadeInDown`}
          style={{
            animationDuration: `${index * 200 + 500}ms`,
          }}
        >
          <div className='mt-1 pr-6'>
            <FaAngleRight />
          </div>
          <div>
            <h6 className='font-semibold'>
              <a
                href={`${node.link}`}
                target='_blank'
                rel='noreferrer'
                className='hover:no-underline'
              >
                {node.title}
              </a>
            </h6>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Qualifications;
